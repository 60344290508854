import React from 'react';

import { Container, Flex, Text, Clickable } from '../lib/atoms';

import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { Github } from '@styled-icons/bootstrap/Github';

export default () => {
    const links = [
        { icon: Github, href: '//linkedin.com/in/lxch' },
        { icon: Linkedin, href: '//github.com/lxchurbakov' },
    ];

    return (
        <Container pt="256px">
            <Text mb="12px" size="52px" weight="500">Aleksandr Churbakov</Text>
            <Text mb="32px" mw="600px" size="22px" weight="300">Fullstack JS/TS developer with a decade of professional experience.</Text>

            <Flex justify="start" gap="12px">
                {links.map(({ icon: Icon, href }) => (
                    <Clickable onClick={() => window.open(href, '_blank')}>
                        <Icon color="#222222" size={32} />
                    </Clickable>
                ))}
            </Flex>
        </Container>
    );
};
